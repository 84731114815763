<template>
  <ContainerPage>
    <div class="d-flex align-items-center" v-if="loading">
      <loader class="m-5"></loader>
      <h2 class="m-0">Chargement...</h2>
    </div>
    <FormationsManagerLeftBar
      v-if="!loading"
      :formations="formations"
    ></FormationsManagerLeftBar>
    <div class="w-100" v-if="!loading">
      <BaseButton
        class="btn btn-primary"
        v-tooltip="'Exporter'"
        @click="exportFormation"
        :loading="loading"
        text="Exporter les résultats(.xls)"
      ></BaseButton>
      <router-view></router-view>
    </div>
  </ContainerPage>
</template>
<script>
import ContainerPage from "@/components/containers/ContainerPage.vue";
import FormationsManagerLeftBar from "@/components/profil/FormationsManagerLeftBar.vue";
import Loader from "@/components/bases/Loader.vue";
import BaseButton from "@/components/bases/Button.vue";

export default {
  components: {
    ContainerPage,
    FormationsManagerLeftBar,
    Loader,
    BaseButton
  },
  data() {
    return {
      formations: [],
      loading: false,
      feedback: {}
    };
  },
  computed: {},
  methods: {
    getFormations: function() {
      this.loading = true;
      this.$http
        .get("/mooc-formations")
        .then(response => (this.formations = response.data))
        .finally(() => (this.loading = false));
    },
    exportFormation() {
      this.loading = true;
      this.$http
        .get("collaborateurs/export/formations-export")
        .then(response => {
          const anchor = document.createElement("a");
          anchor.href =
            "data:text/csv;charset=utf-8," + encodeURIComponent(response.data);
          anchor.target = "_blank";
          anchor.download = "export-formations.csv";
          anchor.click();

          //resolve(response);
        })
        .finally(() => (this.loading = false));
    }
  },
  mounted() {
    this.getFormations();
  }
};
</script>
<style></style>
